import { ColorFamily } from '@graphql-types@';
import { default as PlaceholderAvatar } from 'boring-avatars';
import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import LoadingSpinner from './LoadingSpinner';
import AvatarStatusIcon from './AvatarStatusIcon';
import { PropsOf } from './utils';

const defaultElement = 'div';

export type AvatarVariant =
  | '5xl'
  | '4xl'
  | '3xl'
  | '2xl'
  | 'xl'
  | 'l'
  | 'm'
  | 's'
  | 'xs';
export type AvatarStatus = 'green' | 'red' | 'orange' | 'gray';

export interface AvatarOwnProps<
  E extends React.ElementType = React.ElementType
> {
  name: string;
  src: Maybe<string>;
  as?: E;
  grayscale?: boolean;
  size?: number;
  status?: AvatarStatus;
  alternateStatus?: boolean;
  ring?: ColorFamily;
  statusClassName?: string;
  isLoading?: boolean;
  isSidebar?: boolean;
}

export type AvatarProps<E extends React.ElementType> = AvatarOwnProps<E> &
  Omit<PropsOf<E>, keyof AvatarOwnProps>;

const placeholderInitials = (name: string) => {
  const [firstInitial, secondInitial] = name.split(' ');
  return `${firstInitial?.charAt(0).toUpperCase() || ''}${
    secondInitial?.charAt(0).toUpperCase() || ''
  }`;
};

function AvatarComponent(
  {
    className,
    status,
    alternateStatus = false,
    statusClassName,
    size = 24,
    grayscale,
    ring,
    isLoading,
    name,
    src,
    as,
    isSidebar = false,
    ...props
  }: AvatarProps<typeof defaultElement>,
  ref: React.Ref<HTMLDivElement>
) {
  const Component = as || defaultElement;
  const avatarSize = ring ? size - 8 : size;

  return (
    <Component
      className="relative flex items-center justify-center"
      style={{ width: size, height: size }}
      ref={ref}
      {...props}
    >
      {status && (
        <div
          className="absolute z-30 flex flex-shrink-0 items-center justify-center rounded-full"
          style={{
            height: size * 0.5,
            width: size * 0.5,
            right: '-12%',
            bottom: '-12%',
          }}
        >
          <AvatarStatusIcon
            alternate={alternateStatus}
            size={size}
            status={status}
            className={statusClassName}
          />
        </div>
      )}

      <div
        style={{ width: size, height: size }}
        className={classNames(
          'pointer-events-none absolute top-0 left-0 z-20 flex rounded-full transition-opacity duration-200',
          {
            [ring ? EVENT_COLOR_MAP[ring].border : '']: ring,
            'border-2 opacity-100': ring,
            'opacity-0': !ring || isLoading,
          }
        )}
      />

      <LoadingSpinner
        isLoading={isLoading}
        size={size}
        ring={ring}
        className="pointer-events-none absolute top-0 left-0 z-20 flex"
      />

      <div
        className={classNames(
          'pointer-events-none relative overflow-hidden rounded-full transition-all duration-150',
          className,
          {
            'opacity-60 grayscale group-hover:filter-none': grayscale && !ring,
          }
        )}
        style={{
          width: avatarSize,
          height: avatarSize,
        }}
      >
        <div
          className="absolute top-0 left-0 right-0 z-0 flex items-center justify-center transition-all duration-150"
          style={{
            width: avatarSize,
            height: avatarSize,
          }}
        >
          <PlaceholderAvatar
            size="100%"
            name={name}
            variant="marble"
            colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
          />
        </div>

        {src && (
          <img
            className="absolute top-0 left-0 z-10 h-full w-full select-none rounded-full"
            referrerPolicy="no-referrer"
            alt={name}
            src={src}
          />
        )}
        {!src && isSidebar && (
          <div className="absolute top-0 left-0 z-[5] flex h-full w-full select-none items-center justify-center text-white">
            <span
              style={{
                WebkitBackfaceVisibility: 'hidden',
                MozBackfaceVisibility: 'hidden',
                backfaceVisibility: 'hidden',
                transform: 'TranslateZ(0)',
                WebkitTransformStyle: 'preserve-3d',
                MozTransformStyle: 'preserve-3d',
              }}
              className="w-full origin-center text-center text-xs font-semibold leading-[17px]"
            >
              {placeholderInitials(name)}
            </span>
          </div>
        )}
      </div>
    </Component>
  );
}

const Avatar = React.forwardRef(AvatarComponent) as <
  E extends React.ElementType = typeof defaultElement
>(
  props: AvatarProps<E>
) => JSX.Element;

export default Avatar;
